@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Karma:wght@300;400;500;600;700&display=swap');

body {
    @apply font-sans antialiased bg-neutral-50 text-neutral-900;
    font-family: 'Karma', serif;
}

::-webkit-scrollbar {
    @apply w-[6px] h-[6px];
}

::-webkit-scrollbar-track {
    @apply bg-transparent rounded-full;
}

::-webkit-scrollbar-thumb {
    @apply rounded-full bg-neutral-400;
}

@layer utilities {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .thin-scrollbar::-webkit-scrollbar {
        @apply w-[6px] h-[6px];
    }

    .thin-scrollbar::-webkit-scrollbar-track {
        @apply bg-transparent rounded-full;
    }

    .thin-scrollbar::-webkit-scrollbar-thumb {
        @apply rounded-full bg-neutral-400;
    }

    .mask-overflow {
        @apply py-4;
        /* -webkit-mask-image: linear-gradient(#000, rgba(0,0,0,0.1)); */
        -webkit-mask-image: linear-gradient(180deg, rgba(1,112,152,0) 0%, rgba(2,0,36,1) 5%, rgba(0,0,0,1) 95%, rgba(27,134,209,0) 100%);
    }

    .mask-overflow-horizontal {
        -webkit-mask-image: linear-gradient(90deg, rgba(1,112,152,0) 0%, rgba(2,0,36,1) 3%, rgba(0,0,0,1) 97%, rgba(27,134,209,0) 100%);
    }

    .red-box {
        @apply bg-gradient-to-tr from-rose-800 to-red-400 shadow-md shadow-red-500 text-slate-200;
        /* @apply bg-gradient-to-tr from-neutral-50 to-white shadow-lg shadow-neutral-500 p-8 rounded-xl text-slate-700; */
    }


    .blue-box {
        @apply bg-gradient-to-tr from-blue-800 to-blue-400 shadow-lg shadow-blue-200;
    }
    .fade-blue-box {
        @apply bg-gradient-to-tr from-blue-600 to-blue-400 shadow-lg shadow-blue-200;
    }

    .green-box {
        @apply bg-gradient-to-tr from-green-700 to-teal-400 shadow-lg text-slate-100;
    }

    .orange-box {
        @apply bg-gradient-to-tr from-red-800 to-orange-400 shadow-lg text-slate-100 shadow-orange-500;
    }
}
